import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './tooltip.module.scss';
import { renderText } from 'utils';
import { useIsMobile } from 'hooks';

const Tooltip = ({ tooltipId, containerId, label, body }) => {
  const isMobile = useIsMobile();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const closeTip = () => {
    setIsTooltipVisible(false);
    document.removeEventListener('touchstart', closeTip);
  };

  const showTip = () => {
    const toolTip = document.getElementById(tooltipId);
    const tooltipIconContainer = document.getElementById(containerId);
    if (!isTooltipVisible) {
      //On desktop display the pop-up where the tooltip icon is
      if (!isMobile) {
        const tooltipIconRect = tooltipIconContainer.getBoundingClientRect();
        toolTip.style.top = `${tooltipIconRect.top}px`;
        toolTip.style.left = `${tooltipIconRect.left}px`;
      } else {
        //On mobile display next to parent element instead
        const tooltipIconRect = tooltipIconContainer.parentElement.getBoundingClientRect();
        toolTip.style.top = `${tooltipIconRect.top}px`;
        toolTip.style.left = `${tooltipIconRect.left}px`;
      }
      setIsTooltipVisible(true);
      document.addEventListener('touchstart', closeTip);
    }
  };
  // removed div tags and added spans to fix table render issues
  return (
    <span
      id={containerId}
      role="tooltip"
      aria-label={label}
      onFocus={showTip}
      onMouseOver={showTip}
      onMouseLeave={closeTip}
      className={styles.tooltipContainer}>
      <i onTouchStart={showTip} className={`${styles.tooltipCircle} fa-duotone fa-circle-info`}></i>
      <span
        id={tooltipId}
        className={isTooltipVisible ? styles.tooltip : styles.tooltipHidden}
        aria-hidden={!isTooltipVisible}
        // eslint-disable-next-line jsx-a11y/aria-role
        role="information">
        <i className={`${styles.tooltipClose} fa-solid fa-x`}></i>
        {renderText({ json: body })}
      </span>
    </span>
  );
};

Tooltip.propTypes = {
  tooltipId: PropTypes.string,
  containerId: PropTypes.string,
  label: PropTypes.string,
  body: PropTypes.object,
};

export default Tooltip;
